import { Button, Typography } from "@material-ui/core";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logicAndState } from "./logicAndState";
import { observer } from "mobx-react-lite";

const Component = styled.div`
    width: 91vw;
    margin: auto;
    margin-top: 5rem;
    background-color: white;
    padding: 0.5rem 2vw;
    margin-bottom: 1rem;
    border: solid lightgrey thin;
    @media (max-width: 768px) {
        margin-top: 0rem;
    }
    
`;

const Title = styled(Typography)`
    && {
        margin-top: 4rem;
        font-size: 2rem;
        text-align: center;
        font-weight: bolder;
        @media (max-width: 768px) {
            margin-top: 0rem;
        display: block;
        margin: 1rem 0;
    }
    }
`;

const SubTitle = styled(Typography)`
    && {
        font-size: 1.5rem;
        text-align: center;
    }
`;

const ButtonContainer = styled.div`
    width: 50%;
    margin: auto;
    margin: 4rem auto;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 768px) {
        width: 100%;
        display: block;
        margin: 1rem 0;
    }
    
`;

const StyledButton = styled(Button)`
    && {
        display: block;
        @media (max-width: 768px) {
            width: 50%;
            margin: auto;
            margin-top: 1rem;
        }
    }
`;

const Paragraph = styled(Typography)`
    padding-bottom: 1rem;
    text-align: center;
`;

const ForAnyQuestions = styled(Typography)`
    && {
        margin: 1rem 0;
        text-align: center;
        font-weight: bolder;
    }
`;


type EntrancePageProps = {};

export const EntrancePage = observer(({ }: EntrancePageProps) => {
    let navigate = useNavigate();

    const onClickEnglish = () => {
        navigate("/form?language=en", { replace: true });
        logicAndState.initializeLanguage();
    }

    const onClickFrench = () => {
        navigate("/form?language=fr", { replace: true });
        logicAndState.initializeLanguage();
    }

    return (
        <Component>
            <Title>Rapid Test Result Self-Report / Rapport de résultat de test rapide</Title>
            <SubTitle>Self-reporting for Ontario residents / Rapport de résultat de test rapide pour les résidents de l'Ontario</SubTitle>
            <ButtonContainer>
                <StyledButton size="large" onClick={onClickEnglish} style={{ backgroundColor: "#202020" }} variant="contained">English</StyledButton>
                <Link to="/data">
                    <StyledButton style={{ backgroundColor: "#202020" }} variant="contained" color="success" className="btn btn-lg btn-primary">View Data / Afficher Les Données</StyledButton>
                </Link>
                <StyledButton size="large" onClick={onClickFrench} style={{ backgroundColor: "#202020" }} variant="contained">Français</StyledButton>
            </ButtonContainer>
            <SubTitle>Why Rapid Report?</SubTitle>
            <Paragraph>Due to the surge in COVID cases, PCR testing availability has been restricted to help protect lab capacity for priority groups. The availability of rapid antigen tests (RATs) has enabled Ontarians to conduct their own tests at home. However, there are very few portals to report and congregate RAT results. Rapid Report is meant to allow for the congregation of RAT data for research and the public interest. </Paragraph>

            <SubTitle>How will my data be used?</SubTitle>
            <Paragraph>The data collected on our form will be aggregated and made available to the public to help gain a more accurate picture of COVID spread in Ontario. This site does not collect any personal information that can be used to identify a specific individual. We collect all fields completed on our forms and make that data publicly available. To gain useful data, we suggest you fill out as many fields as possible. In order to protect the integrity of our data, IP addresses are stored in the database to protect from the entry of false data. IP addresses will not be publicly available.  Our database is periodically cleaned up to maintain the accuracy of the numbers available on this website.</Paragraph>
            
            <ForAnyQuestions>For any questions please contact: &#101;hegel&#64;uwo&#46;ca</ForAnyQuestions>
        </Component>
    );
});
