import axios from "axios";
import { createBrowserHistory } from "history";
import { makeAutoObservable } from "mobx";
import moment from "moment";
import { englishTranslations, frenchTranslations } from "./i18next";
import toastr from "toastr";
import i18next, { TFunction } from "i18next";
import { NavigateFunction } from "react-router-dom";

const fakeDate = 
[
        {
            "_id": "61d0869cb61cb6001fc3ca60",
            "resultOfTest": "negative",
            "timeStamp": "01/01/2022 16:51:40",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "p0j1p0",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "male",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0db7ab61cb6001fc3ca61",
            "resultOfTest": "negative",
            "timeStamp": "01/01/2022 22:53:46",
            "dateTestWasTaken": "01/29/2021",
            "postalCode": "K1S 0A8",
            "symptoms": {
                "newOnsetCoughOrWorseningCough": true,
                "soreThroat": true,
                "runnyNoseNasalCongestion": true
            },
            "gender": "male",
            "ageGroup": "18-29",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0dbdab61cb6001fc3ca62",
            "resultOfTest": "negative",
            "timeStamp": "01/01/2022 22:55:22",
            "dateTestWasTaken": "12/22/2021",
            "postalCode": "K1S 1M9",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0dc1cb61cb6001fc3ca63",
            "resultOfTest": "negative",
            "timeStamp": "01/01/2022 22:56:28",
            "dateTestWasTaken": "12/22/2021",
            "postalCode": "K1S 0A8",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "male",
            "ageGroup": "18-29",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0dc53b61cb6001fc3ca64",
            "resultOfTest": "negative",
            "timeStamp": "01/01/2022 22:57:23",
            "dateTestWasTaken": "12/24/2021",
            "postalCode": "M6H 3J5",
            "symptoms": {},
            "gender": "male",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "2",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0dc98b61cb6001fc3ca65",
            "resultOfTest": "negative",
            "timeStamp": "01/01/2022 22:58:32",
            "dateTestWasTaken": "12/30/2021",
            "postalCode": "K1S 0A8",
            "symptoms": {
                "newOnsetCoughOrWorseningCough": true,
                "soreThroat": true
            },
            "gender": "male",
            "ageGroup": "18-29",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0dcc2b61cb6001fc3ca66",
            "resultOfTest": "negative",
            "timeStamp": "01/01/2022 22:59:14",
            "dateTestWasTaken": "12/22/2021",
            "postalCode": "K1S 0A8",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0dcf3b61cb6001fc3ca67",
            "resultOfTest": "negative",
            "timeStamp": "01/01/2022 23:00:03",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "K1S 0A8",
            "symptoms": {
                "runnyNoseNasalCongestion": true
            },
            "gender": "male",
            "ageGroup": "18-29",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0dd15b61cb6001fc3ca68",
            "resultOfTest": "positive",
            "timeStamp": "01/01/2022 23:00:37",
            "dateTestWasTaken": "12/30/2021",
            "symptoms": {},
            "gender": "female"
        },
        {
            "_id": "61d0dd80b61cb6001fc3ca6a",
            "resultOfTest": "negative",
            "timeStamp": "01/01/2022 23:02:24",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "K1S 0A8",
            "symptoms": {
                "soreThroat": true
            },
            "gender": "male",
            "ageGroup": "18-29",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0dda7b61cb6001fc3ca6b",
            "resultOfTest": "negative",
            "timeStamp": "01/01/2022 23:03:03",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "K1S 0A8",
            "symptoms": {
                "soreThroat": true
            },
            "gender": "male",
            "ageGroup": "18-29",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0fb65b61cb6001fc3ca6c",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 01:09:57",
            "postalCode": "K1H6B4",
            "symptoms": {
                "runnyNoseNasalCongestion": true,
                "headache": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0ff0cb61cb6001fc3ca6d",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 01:25:32",
            "dateTestWasTaken": "12/26/2021",
            "postalCode": "N2t1z7 ",
            "symptoms": {
                "soreThroat": true,
                "unexplainedFatigueLethargyMalaiseMuscleAches": true
            },
            "gender": "female",
            "ageGroup": "40-49",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d0ffaab61cb6001fc3ca6e",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 01:28:10",
            "dateTestWasTaken": "12/29/2021",
            "postalCode": "N3H2s1",
            "symptoms": {
                "soreThroat": true
            },
            "gender": "female",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "2",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10241b61cb6001fc3ca6f",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 01:39:13",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "L7A 0X9",
            "symptoms": {
                "newOnsetCoughOrWorseningCough": true,
                "feverAndOrChills": true,
                "shortnessOfBreath": true
            },
            "gender": "female",
            "ageGroup": "40-49",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d102b3b61cb6001fc3ca70",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 01:41:07",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "K2e 5l6",
            "symptoms": {
                "feverAndOrChills": true,
                "newOnsetCoughOrWorseningCough": true,
                "soreThroat": true
            },
            "gender": "female",
            "ageGroup": "40-49",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d102c5b61cb6001fc3ca71",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 01:41:25",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "K2A3R4",
            "symptoms": {
                "headache": true,
                "soreThroat": true,
                "feverAndOrChills": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10339b61cb6001fc3ca72",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 01:43:21",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "N3R2Z6",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10386b61cb6001fc3ca73",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 01:44:38",
            "dateTestWasTaken": "12/27/2021",
            "postalCode": "L5a2n1 ",
            "symptoms": {
                "newOnsetCoughOrWorseningCough": true,
                "soreThroat": true,
                "runnyNoseNasalCongestion": true,
                "nauseaVomitingDiarrhea": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d1043db61cb6001fc3ca74",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 01:47:41",
            "dateTestWasTaken": "12/26/2021",
            "postalCode": "N0B2K0 ",
            "symptoms": {
                "feverAndOrChills": true,
                "newOnsetCoughOrWorseningCough": true,
                "shortnessOfBreath": true,
                "unexplainedFatigueLethargyMalaiseMuscleAches": true,
                "soreThroat": true,
                "runnyNoseNasalCongestion": true,
                "headache": true
            },
            "gender": "female",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "2",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d104d6b61cb6001fc3ca75",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 01:50:14",
            "dateTestWasTaken": "12/30/2021",
            "postalCode": "M6P3N9",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10570b61cb6001fc3ca76",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 01:52:48",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "N2t1z7 ",
            "symptoms": {
                "shortnessOfBreath": true,
                "soreThroat": true,
                "newOnsetCoughOrWorseningCough": true
            },
            "gender": "male",
            "ageGroup": "80+",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d105adb61cb6001fc3ca77",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 01:53:49",
            "dateTestWasTaken": "12/26/2021",
            "postalCode": "N2t1z7 ",
            "symptoms": {
                "newOnsetCoughOrWorseningCough": true,
                "runnyNoseNasalCongestion": true,
                "soreThroat": true
            },
            "gender": "female",
            "ageGroup": "70-79",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10630b61cb6001fc3ca78",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 01:56:00",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "N3c 4m1",
            "symptoms": {
                "runnyNoseNasalCongestion": true
            },
            "gender": "female",
            "ageGroup": "40-49",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10732b61cb6001fc3ca79",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:00:18",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "M1W3S4",
            "symptoms": {},
            "gender": "female",
            "ageGroup": "18-29",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10734b61cb6001fc3ca7a",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:00:20",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "L1v5e6",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "male",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10741b61cb6001fc3ca7b",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:00:33",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "K2J 3L3",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "prefer-not-to-say",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d1075db61cb6001fc3ca7c",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:01:01",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "l1v5e6",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d1076cb61cb6001fc3ca7d",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:01:16",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "M6G 1W7",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "40-49",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d107b8b61cb6001fc3ca7e",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:02:32",
            "dateTestWasTaken": "12/23/2021",
            "postalCode": "M4S2H6",
            "symptoms": {
                "soreThroat": true,
                "headache": true,
                "runnyNoseNasalCongestion": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d107c0b61cb6001fc3ca7f",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:02:40",
            "dateTestWasTaken": "12/29/2021",
            "postalCode": "L6L1X8",
            "symptoms": {
                "runnyNoseNasalCongestion": true
            },
            "gender": "male",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d107f7b61cb6001fc3ca80",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:03:35",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "l1v5e6",
            "symptoms": {
                "feverAndOrChills": true
            },
            "gender": "male",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "2",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d107fab61cb6001fc3ca81",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:03:38",
            "dateTestWasTaken": "12/28/2021",
            "postalCode": "L8S1H2",
            "symptoms": {
                "runnyNoseNasalCongestion": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d107fab61cb6001fc3ca82",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:03:38",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "M6R 2R6",
            "symptoms": {
                "feverAndOrChills": true,
                "headache": true,
                "unexplainedFatigueLethargyMalaiseMuscleAches": true
            },
            "gender": "female",
            "ageGroup": "40-49",
            "numberOfVaccinesReceived": "2",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10838b61cb6001fc3ca83",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:04:40",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "M8V2S4",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "18-29",
            "numberOfVaccinesReceived": "2",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10841b61cb6001fc3ca84",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:04:49",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "K7p1n6 ",
            "symptoms": {},
            "gender": "male",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10848b61cb6001fc3ca85",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:04:56",
            "dateTestWasTaken": "12/30/2021",
            "postalCode": "N1h 5E5 ",
            "symptoms": {
                "headache": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10852b61cb6001fc3ca86",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 02:05:06",
            "dateTestWasTaken": "12/24/2021",
            "postalCode": "K2J4N8",
            "symptoms": {
                "noneOfTheAbove": false,
                "shortnessOfBreath": true
            },
            "gender": "male",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10857b61cb6001fc3ca87",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 02:05:11",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "K2V 0L4",
            "symptoms": {
                "feverAndOrChills": true
            },
            "gender": "female",
            "ageGroup": "0-11",
            "numberOfVaccinesReceived": "0",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d1088cb61cb6001fc3ca88",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:06:04",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "N2H 3P9",
            "symptoms": {
                "soreThroat": true,
                "runnyNoseNasalCongestion": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d1089ab61cb6001fc3ca89",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:06:18",
            "dateTestWasTaken": "12/26/2021",
            "postalCode": "N2T 1P8",
            "symptoms": {
                "feverAndOrChills": false,
                "newOnsetCoughOrWorseningCough": true,
                "unexplainedFatigueLethargyMalaiseMuscleAches": true,
                "nauseaVomitingDiarrhea": true,
                "runnyNoseNasalCongestion": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d1089eb61cb6001fc3ca8a",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:06:22",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "N5A 2M4",
            "symptoms": {
                "runnyNoseNasalCongestion": true,
                "headache": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d108cdb61cb6001fc3ca8b",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:07:09",
            "dateTestWasTaken": "12/30/2021",
            "postalCode": "N6K 2G6",
            "symptoms": {
                "headache": true
            },
            "gender": "female",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d108d3b61cb6001fc3ca8c",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:07:15",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "L6H4M3 ",
            "symptoms": {
                "runnyNoseNasalCongestion": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d108d7b61cb6001fc3ca8d",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:07:19",
            "dateTestWasTaken": "12/28/2021",
            "postalCode": "N2T 1P8",
            "symptoms": {
                "feverAndOrChills": true,
                "newOnsetCoughOrWorseningCough": true,
                "unexplainedFatigueLethargyMalaiseMuscleAches": true,
                "nauseaVomitingDiarrhea": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d1090bb61cb6001fc3ca8e",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:08:11",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "L1R 2R4",
            "symptoms": {
                "headache": true,
                "unexplainedFatigueLethargyMalaiseMuscleAches": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10925b61cb6001fc3ca8f",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:08:37",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "N5X 4H1",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d1092db61cb6001fc3ca90",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:08:45",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "N2T 1P8",
            "symptoms": {
                "feverAndOrChills": true,
                "newOnsetCoughOrWorseningCough": true,
                "unexplainedFatigueLethargyMalaiseMuscleAches": true,
                "nauseaVomitingDiarrhea": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10932b61cb6001fc3ca91",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:08:50",
            "dateTestWasTaken": "12/30/2021",
            "postalCode": "K6H1G8",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "male",
            "ageGroup": "60-69",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10969b61cb6001fc3ca92",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 02:09:45",
            "dateTestWasTaken": "12/27/2021",
            "symptoms": {
                "feverAndOrChills": true,
                "soreThroat": true,
                "runnyNoseNasalCongestion": true,
                "headache": true
            },
            "gender": "female",
            "numberOfVaccinesReceived": "2",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10998b61cb6001fc3ca93",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:10:32",
            "dateTestWasTaken": "12/31/2021",
            "postalCode": "M6P2S6",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": true
        },
        {
            "_id": "61d109aeb61cb6001fc3ca94",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 02:10:54",
            "dateTestWasTaken": "12/29/2021",
            "postalCode": "N3B3M8 ",
            "symptoms": {
                "feverAndOrChills": true,
                "newOnsetCoughOrWorseningCough": true,
                "shortnessOfBreath": true,
                "unexplainedFatigueLethargyMalaiseMuscleAches": true,
                "soreThroat": true,
                "runnyNoseNasalCongestion": true,
                "headache": true
            },
            "gender": "nonBinary",
            "ageGroup": "12-17",
            "numberOfVaccinesReceived": "2",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d109beb61cb6001fc3ca95",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:11:10",
            "postalCode": "N2t1c8 ",
            "symptoms": {
                "newOnsetCoughOrWorseningCough": true,
                "nauseaVomitingDiarrhea": true
            },
            "gender": "prefer-not-to-say",
            "ageGroup": "0-11",
            "numberOfVaccinesReceived": "0",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d109c4b61cb6001fc3ca96",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:11:16",
            "dateTestWasTaken": "12/25/2021",
            "postalCode": "M1C1Z7",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "50-59",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d109e9b61cb6001fc3ca97",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 02:11:53",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "M4M3E7",
            "symptoms": {
                "feverAndOrChills": true,
                "soreThroat": true
            },
            "gender": "female",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d109f6b61cb6001fc3ca98",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 02:12:06",
            "dateTestWasTaken": "12/30/2021",
            "postalCode": "M1K1P2",
            "symptoms": {
                "newOnsetCoughOrWorseningCough": true,
                "unexplainedFatigueLethargyMalaiseMuscleAches": true,
                "nauseaVomitingDiarrhea": true,
                "runnyNoseNasalCongestion": true,
                "soreThroat": true
            },
            "gender": "female",
            "ageGroup": "18-29",
            "numberOfVaccinesReceived": "2",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10a39b61cb6001fc3ca99",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:13:13",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "L7M3Y1",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "0-11",
            "numberOfVaccinesReceived": "0",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10afcb61cb6001fc3ca9a",
            "resultOfTest": "positive",
            "timeStamp": "01/02/2022 02:16:28",
            "dateTestWasTaken": "01/01/2022",
            "postalCode": "L5M6B8",
            "symptoms": {
                "runnyNoseNasalCongestion": true
            },
            "gender": "female",
            "ageGroup": "18-29",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        },
        {
            "_id": "61d10b06b61cb6001fc3ca9b",
            "resultOfTest": "negative",
            "timeStamp": "01/02/2022 02:16:38",
            "dateTestWasTaken": "12/30/2021",
            "postalCode": "N1G2W6",
            "symptoms": {
                "noneOfTheAbove": true
            },
            "gender": "female",
            "ageGroup": "30-39",
            "numberOfVaccinesReceived": "3",
            "travelledOutsideCanadaInPast14Days": false
        }] as any;

export type AgeGroup = "0-11" | "12-17" | "18-29" | "30-39" | "40-49" | "50-59" | "60-69" | "70-79" | "80+";
export const typeAgeGroupArray = [
    "0-11", "12-17", "18-29", "30-39", "40-49", "50-59", "60-69", "70-79", "80+"

];

export type Gender = "male" | "female" | "non-binary";

export type PotentialNumberOfVaccinesReceived = "0" | "1" | "2" | "3" | "4+";

export const symptomsArray = [
    "feverAndOrChills",
    "newOnsetCoughOrWorseningCough",
    "shortnessOfBreath",
    "decreaseOrLossInTheSensesOfTasteOrSmell",
    "unexplainedFatigueLethargyMalaiseMuscleAches",
    "nauseaVomitingDiarrhea",
    "soreThroat",
    "runnyNoseNasalCongestion",
    "abdominalPain",
    "headache",
    "conjunctivitis",
    "decreasedAppetite",
    "noneOfTheAbove",
];

const defaultValues = {
    timeStamp: null,
    resultOfTest: null,
    dateTestWasTaken: null,
    postalCode: null,
    symptoms: null,
    gender: null,
    ageGroup: null,
    numberOfVaccinesReceived: null,
    travelledOutsideCanadaInPast14Days: null
}

const numberOfDaysToDisplay = 30;

class LogicAndState {
    isShowingSelfReportForm: boolean;

    loadingDataPage: boolean;
    responses: {
        timeStamp: string | null;
        resultOfTest: string | null;
        dateTestWasTaken: string | null;
        postalCode: string | null;
        symptoms: {
            feverAndOrChills?: boolean,
            newOnsetCoughOrWorseningCough?: boolean,
            shortnessOfBreath?: boolean,
            decreaseOrLossInTheSensesOfTasteOrSmell?: boolean,
            unexplainedFatigueLethargyMalaiseMuscleAches?: boolean,
            nauseaVomitingDiarrhea?: boolean,
            soreThroat?: boolean,
            runnyNoseNasalCongestion?: boolean,
            abdominalPain?: boolean,
            headache?: boolean,
            conjunctivitis?: boolean,
            decreasedAppetite?: boolean,
            noneOfTheAbove?: boolean,
        }
        gender: Gender | null;
        ageGroup: AgeGroup | null;
        numberOfVaccinesReceived: PotentialNumberOfVaccinesReceived | null;
        travelledOutsideCanadaInPast14Days: boolean | null;
        areYouSubmittingThisOnBehalfOfSomeoneElse: boolean | null;
        whatWasYourReasonForTesting: {
            symptoms?: boolean,
            notifiedAsCloseContact?: boolean,
            travel?: boolean,
            asymptomaticScreening?: boolean,
            other?: boolean
        }
    }[];

    resultOfTest: string | null;
    dateTestWasTaken: moment.Moment | null;
    postalCode: string | null;
    symptoms: {
        feverAndOrChills?: boolean,
        newOnsetCoughOrWorseningCough?: boolean,
        shortnessOfBreath?: boolean,
        decreaseOrLossInTheSensesOfTasteOrSmell?: boolean,
        unexplainedFatigueLethargyMalaiseMuscleAches?: boolean,
        nauseaVomitingDiarrhea?: boolean,
        soreThroat?: boolean,
        runnyNoseNasalCongestion?: boolean,
        abdominalPain?: boolean,
        headache?: boolean,
        conjunctivitis?: boolean,
        decreasedAppetite?: boolean,
        noneOfTheAbove?: boolean,
    }
    gender: Gender | null;
    ageGroup: AgeGroup | null;
    numberOfVaccinesReceived: PotentialNumberOfVaccinesReceived | null;
    travelledOutsideCanadaInPast14Days: boolean | null;
    areYouSubmittingThisOnBehalfOfSomeoneElse: boolean | null;
    whatWasYourReasonForTesting: {
        symptoms?: boolean,
        notifiedAsCloseContact?: boolean,
        travel?: boolean,
        asymptomaticScreening?: boolean,
        other?: boolean
    }

    positiveCasesPerDay: { date: string, numberOfPositiveCases: number }[];
    proportionOfPositiveTests: { date: string, numberOfPositiveCases: number, numberOfOtherCases: number }[];
    numberOfReportedCasesPerDay: { date: string, numberOfReportedTests: number }[];


    excelFriendlyJSON: {
        timeStamp: string | null;
        resultOfTest: string | null;
        dateTestWasTaken: string | null;
        postalCode: string | null;
        symptoms: string | null;
        gender: Gender | null;
        ageGroup: AgeGroup | null;
        numberOfVaccinesReceived: PotentialNumberOfVaccinesReceived | null;
        travelledOutsideCanadaInPast14Days: string | boolean | null;
        areYouSubmittingThisOnBehalfOfSomeoneElse: string | boolean | null;
        whatWasYourReasonForTesting: string | null;
    }[]

    constructor() {
        this.isShowingSelfReportForm = false;
        this.resultOfTest = null;
        this.dateTestWasTaken = null;
        this.postalCode = null;
        this.symptoms = {};
        this.gender = null;
        this.ageGroup = null;
        this.travelledOutsideCanadaInPast14Days = null;
        this.numberOfVaccinesReceived = null;
        this.responses = [];
        this.loadingDataPage = false;
        this.positiveCasesPerDay = [];
        this.proportionOfPositiveTests = [];
        this.excelFriendlyJSON = [];
        this.areYouSubmittingThisOnBehalfOfSomeoneElse = null;
        this.whatWasYourReasonForTesting = {};
        this.numberOfReportedCasesPerDay = [];
        makeAutoObservable(this);
        this.initialize();
    }

    async initialize() {
        try {
            this.loadingDataPage = true;
            this.initializeLanguage();
            this.resetFormData();
            const responsesRes = await axios.get(`${process.env.REACT_APP_BASEURL}/`);

            if (!responsesRes.data.responses || !Array.isArray(responsesRes.data.responses)) throw "";
            this.responses = responsesRes.data.responses;

            // this.buildPositiveCasesPerDay();
            // this.buildProportionOfPositiveTests();
            this.buildNumberOfTestsReportedEachDay();
            this.buildExcelFriendlyJSON();
            this.loadingDataPage = false;
        } catch (err) {
            toastr.error("Error");
            this.loadingDataPage = false
        }
    }


    // Obviously not great to pass these in, but it works and I don't wanna spend the effort to change it
    async submit(t: TFunction, navigate: NavigateFunction) {
        try {
            if (!this.postalCode) throw `${t("pleaseFillOut")} ${t("invalidPostCode")}`;
            if (!this.dateTestWasTaken) throw `${t("pleaseFillOut")} ${t('onWhatDateDidYouPerformYourTest')}`;
            if (this.resultOfTest === null) throw `${t("pleaseFillOut")} ${t("wasYourTestResult")}`;
            if (this.postalCode && this.postalCode.length >= 0 && !(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]$/.test(this.postalCode))) {
                throw t("invalidPostCode");
            }
            if (this.postalCode && this.postalCode.length >= 0 && (this.postalCode === "H0H" || this.postalCode === "H0H")) throw t("invalidPostCode");


            let formattedDateTestWasTaken = null;
            if (this.dateTestWasTaken) {
                formattedDateTestWasTaken = this.dateTestWasTaken.format("L");
            }

            const res = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_BASEURL}/`,
                data: {
                    ...(formattedDateTestWasTaken && { dateTestWasTaken: formattedDateTestWasTaken }),
                    ...(this.resultOfTest && { resultOfTest: this.resultOfTest }),
                    ...(this.postalCode && { postalCode: this.postalCode }),
                    ...(this.symptoms && { symptoms: this.symptoms }),
                    ...(this.gender && { gender: this.gender }),
                    ...(this.ageGroup && { ageGroup: this.ageGroup }),
                    ...(this.numberOfVaccinesReceived && { numberOfVaccinesReceived: this.numberOfVaccinesReceived }),
                    ...(this.travelledOutsideCanadaInPast14Days !== null && { travelledOutsideCanadaInPast14Days: this.travelledOutsideCanadaInPast14Days }),
                    ...(this.whatWasYourReasonForTesting !== null && { whatWasYourReasonForTesting: this.whatWasYourReasonForTesting }),
                    ...(this.areYouSubmittingThisOnBehalfOfSomeoneElse !== null && { areYouSubmittingThisOnBehalfOfSomeoneElse: this.areYouSubmittingThisOnBehalfOfSomeoneElse }),
                }
            });
            if (res.status != 200) throw null;

            logicAndState.initialize();
            toastr.success(t("thankYouForSubmittingData"));

            let params = (new URL(document.location as any)).searchParams;
            navigate(`/data${typeof params.get('language') === "string" ? `?language=${params.get('language')}` : ""}`);
        } catch (err) {
            console.log(err);
            if (typeof err === "string") toastr.error(err);
            else toastr.error(t("generalError"));
        }
    }

    resetFormData() {
        this.resultOfTest = null;
        this.dateTestWasTaken = null;
        this.postalCode = null;
        this.symptoms = {};
        this.gender = null;
        this.ageGroup = null;
        this.travelledOutsideCanadaInPast14Days = null;
        this.numberOfVaccinesReceived = null;
        this.areYouSubmittingThisOnBehalfOfSomeoneElse = null;
        this.whatWasYourReasonForTesting = {};
    }

    initializeLanguage() {
        let params = (new URL(document.location as any)).searchParams;
        if (typeof params.get('language') === "string") {
            i18next.changeLanguage(params.get('language') as string);
        } else {
            i18next.changeLanguage(navigator.language);
        }
    }


    buildNumberOfTestsReportedEachDay() {
        try {
            let tempNumberOfReportedCasesPerDay = [] as { date: string, numberOfReportedTests: number }[];
            // const tempDate = moment("01/01/2022");
            const tempDate = moment({day: 1, month: 2, year: 2022});
            while (true) {
                tempNumberOfReportedCasesPerDay.push({ date: tempDate.format("L"), numberOfReportedTests: 0 });
                if (moment(moment().format("L")).diff(tempDate, "day") === 0) break;
                tempDate.add("days", 1);
            }

            this.responses.forEach(({ timeStamp }) => {
               const dateOfReport = moment(timeStamp);
               tempNumberOfReportedCasesPerDay = tempNumberOfReportedCasesPerDay.map(ele => {
                   if (ele.date === dateOfReport.format("L")) {
                       return {...ele, numberOfReportedTests: ele.numberOfReportedTests + 1}
                   }
                   return {...ele};
               });
            });
            this.numberOfReportedCasesPerDay = tempNumberOfReportedCasesPerDay;
            } catch (err) {
            console.log(err);
        }
    }

    buildPositiveCasesPerDay() {
        let tempPositiveCasesPerDay = [] as { date: string, numberOfPositiveCases: number }[];
        const tempDate = moment().add("days", -numberOfDaysToDisplay);
        for (let x = 0; x < numberOfDaysToDisplay + 1; x++) {
            tempPositiveCasesPerDay.push({ date: tempDate.format("L"), numberOfPositiveCases: 0 });
            tempDate.add("days", 1);
        }
        this.responses.forEach(({ resultOfTest, dateTestWasTaken }) => {
            if (resultOfTest === "positive") {
                tempPositiveCasesPerDay = tempPositiveCasesPerDay.map(({ date, numberOfPositiveCases }) => {
                    if (date === dateTestWasTaken) {
                        return {
                            date: date,
                            numberOfPositiveCases: numberOfPositiveCases + 1
                        }
                    }
                    return { numberOfPositiveCases, date };
                })
            }
        })
        this.positiveCasesPerDay = tempPositiveCasesPerDay;
    }

    buildProportionOfPositiveTests() {
        let tempProportionOfPositiveTests = [] as { date: string, numberOfPositiveCases: number, numberOfOtherCases: number }[];
        const tempDate = moment().add("days", -numberOfDaysToDisplay);
        for (let x = 0; x < numberOfDaysToDisplay + 1; x++) {
            tempProportionOfPositiveTests.push({ date: tempDate.format("L"), numberOfPositiveCases: 0, numberOfOtherCases: 0 });
            tempDate.add("days", 1);
        }
        this.responses.forEach(({ resultOfTest, dateTestWasTaken }) => {
            tempProportionOfPositiveTests = tempProportionOfPositiveTests.map(({ date, numberOfPositiveCases, numberOfOtherCases }) => {
                if (date === dateTestWasTaken) {
                    if (resultOfTest === "positive") {
                        return {
                            date,
                            numberOfPositiveCases: numberOfPositiveCases + 1,
                            numberOfOtherCases
                        }
                    } else {
                        return {
                            date,
                            numberOfPositiveCases,
                            numberOfOtherCases: numberOfOtherCases + 1
                        }
                    }

                }
                return { numberOfPositiveCases, date, numberOfOtherCases };
            })
        })

        this.proportionOfPositiveTests = tempProportionOfPositiveTests.map(ele => {
            if (ele.numberOfPositiveCases === 0 || ele.numberOfOtherCases === 0) return { ...ele, percentage: 0 };
            return { ...ele, percentage: Math.round((ele.numberOfPositiveCases / (ele.numberOfOtherCases + ele.numberOfPositiveCases)) * 100) }
        });
    }

    buildExcelFriendlyJSON() {
        try {
            this.excelFriendlyJSON = this.responses.map(ele => {
                let symptomsString = "";
                if (ele.symptoms) {
                    Object.entries(ele.symptoms).forEach((symptom) => {
                        if (symptom[1]) {
                            symptomsString += symptom[0].replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }) + ", ";
                        }
                    });
                }

                let reasonForTestingString = "";
                if (ele.whatWasYourReasonForTesting) {
                    Object.entries(ele.whatWasYourReasonForTesting).forEach((reason) => {
                        if (reason[1]) {
                            reasonForTestingString += reason[0].replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }) + ", ";
                        }
                    });
                }
                return {
                    ...defaultValues,
                    ...ele,
                    symptoms: symptomsString,
                    travelledOutsideCanadaInPast14Days: ele.travelledOutsideCanadaInPast14Days === false ? "FALSE" : ele.travelledOutsideCanadaInPast14Days,
                    areYouSubmittingThisOnBehalfOfSomeoneElse: ele.areYouSubmittingThisOnBehalfOfSomeoneElse === false ? "FALSE" : ele.areYouSubmittingThisOnBehalfOfSomeoneElse,
                    whatWasYourReasonForTesting: reasonForTestingString
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    onInitializeSelfReportFormPage() {
        this.isShowingSelfReportForm = true;
    }

    onLeaveSelfReportFormPage() {
        this.isShowingSelfReportForm = false;
    }

    updateDateTestWasTaken(newValue: moment.Moment) {
        this.dateTestWasTaken = newValue;
    }

    updateResultOfTest(newValue: string) {
        this.resultOfTest = newValue;
    }

    updatePostalCode(newValue: string) {
        if (newValue.length >= 4) return;
        this.postalCode = newValue
    }
}

export const logicAndState = new LogicAndState();
