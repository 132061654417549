import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const englishTranslations = {
  COVIDSelfReportOntario: "Rapid Report",
  goToSelfReportForm: "Go To Self Report Form",
  wasYourTestResult: "What was your test result?",
  positive: "positive",
  negative: "negative",
  inconclusive: "inconclusive",
  onWhatDateDidYouPerformYourTest: "On what date did you perform your test?",
  whatIsYourPostalCode: "What are the first 3 characters of your postal code?",
  haveYouHadAnyOfTheFollowingSymptomsInThePastTenDays: "Have you had any of the following symptoms in the past ten days?",
  feverAndOrChills: "fever and or chills",
  newOnsetCoughOrWorseningCough: "new onset cough or worsening cough",
  shortnessOfBreath: "shortness of breath",
  decreaseOrLossInTheSensesOfTasteOrSmell: "decrease or loss in the senses of taste or smell",
  unexplainedFatigueLethargyMalaiseMuscleAches: "unexplained fatigue/lethargy/malaise/ muscle aches",
  nauseaVomitingDiarrhea: "nausea/vomiting/diarrhea",
  soreThroat: "sore throat",
  runnyNoseNasalCongestion: "runny nose/nasal congestion",
  abdominalPain: "abdominal pain",
  headache: "headache",
  conjunctivitis: "conjunctivitis",
  decreasedAppetite: "decreased appetite",
  noneOfTheAbove: "none of the above",
  whatIsYourGender: "What is your gender?",
  male: "male",
  female: "female",
  nonBinary: "non-binary",
  preferNotToRespond: "prefer not to respond",
  whatIsYourAgeGroup: "What is your age group?",
  howManyDosesOfCOVIDVaccineHaveYouReceived: "How many doses of COVID vaccine have you received?",
  haveYouTravelledOutsideOfCanadaInThePast14Days: "Have you travelled outside of Canada in the past 14 days?",
  yes: "yes",
  no: "no",
  submit: "submit",
  thankYouForSubmittingData: "Thank you for submitting data. You have made this pandemic easier for everyone.",
  ofTestsReportedToTheSite: " Tests Reported to the Site",
  generalError: "Something went wrong. Please try again or refresh the page",
  positiveRapidTests: "Positive Rapid Tests",
  proportionOfPositiveTests: "Proportion Of Positive Tests (Reported in %)",
  download: "Download Data",
  optionalQuestions: "Optional questions",
  invalidPostCode: "Your postal code is invalid. Please use this format E.X K1S",
  goToDataPage: "Go to data page",
  changeLanguage: "Change language",
  clickHere: "Click Here",
  pleaseFillOut: "Please fill out:",
  thankYouForReportingYourTest: "Than you for reporting your test!",
  ifYouWouldLikeToBeRemindedToReport: "If you would like to be reminded to report your test. Please consider following us on social media where we will post reminders ever monday and friday to report your test.",
  subscribe: "Subscribe",
  emailAddress: "Email Address",
  areYouSubmittingThisOnBehalfOfSomeoneElse: "Are you submitting this on behalf of someone else?",
  whatWasYourReasonForTesting: "What was your reason for testing? (Check all that apply)",
  symptoms: "Symptoms",
  notifiedAsCloseContact: "Due to close contact testing positive for COVID-19",
  travel: "Travel",
  asymptomaticScreening: "Asymptomatic screening",
  other: "Other",
  numberOfTestsReportedEachDay: "Number of Tests Reported Each Day"
}

export const frenchTranslations = {
  COVIDSelfReportOntario: "Rapid Report",
  goToSelfReportForm: "Aller au formulaire d'auto-rapport",
  wasYourTestResult: "Avez vous obtenu un résultat positif ou negatif ?",
  positive: "positif",
  negative: "négatif",
  inconclusive: "non-conclusive", // non-conclusive
  onWhatDateDidYouPerformYourTest: "À quelle date avez-vous fait votre test ?",
  whatIsYourPostalCode: "Quels sont les trois premiers caractères de votre code postal ?",
  haveYouHadAnyOfTheFollowingSymptomsInThePastTenDays: "Au cours des 10 derniers jours, est-ce que vous eu un des symptômes suivants ?",
  feverAndOrChills: "Fièvre et/ou frissons",
  newOnsetCoughOrWorseningCough: "Nouvelle toux ou aggravation d'une toux chronique",
  shortnessOfBreath: "Essoufflement",
  decreaseOrLossInTheSensesOfTasteOrSmell: "Diminution ou perte du goût ou de l'odorat",
  unexplainedFatigueLethargyMalaiseMuscleAches: "Fatigue/léthargie/malaise/douleurs musculaires inexpliquées",
  nauseaVomitingDiarrhea: "Nausée/vomissements, diarrhée",
  soreThroat: "Maux de gorge",
  runnyNoseNasalCongestion: "Écoulement nasal ou congestion nasale",
  abdominalPain: "Douleurs abdominales",
  headache: "Maux de tête",
  conjunctivitis: "Conjonctivite",
  decreasedAppetite: "Perte d'appetit",
  noneOfTheAbove: "Aucune de ces réponses",
  whatIsYourGender: "Quel est votre sexe ?",
  male: "Homme",
  female: "Femme",
  nonBinary: "Non-binaire",
  preferNotToRespond: "Je ne souhaite pas le préciser",
  whatIsYourAgeGroup: "Quel âge avez-vous ?",
  howManyDosesOfCOVIDVaccineHaveYouReceived: "Combien de doses de vaccin COVID avez-vous reçues ?",
  haveYouTravelledOutsideOfCanadaInThePast14Days: "Est-ce que vous avez un voyage à l'exterieur du Canada au cours des 14 dernier jours ?",
  yes: "oui",
  no: "non",
  submit: "soumettre",
  thankYouForSubmittingData: "Merci d'avoir soumis des données. Vous avez rendu cette pandémie plus facile pour tout le monde.",
  ofTestsReportedToTheSite: "des tests signalés au site",
  generalError: "Une erreur s'est produite. Veuillez réessayer ou actualiser la page",
  positiveRapidTests: "Tests rapides positifs",
  proportionOfPositiveTests: "Proportion de tests positifs (rapportés en %)",
  download: "Télécharger les données",
  optionalQuestions: "Questions facultatives",
  invalidPostCode: "Votre code postal est invalide. Veuillez utiliser ce format E.X K1S",
  goToDataPage: "Aller à la page de données",
  changeLanguage: "Changer de langue",
  clickHere: "Cliquez ici",
  pleaseFillOut: "Veuillez compléter: ",
  thankYouForReportingYourTest: "Merci d'avoir signalé votre test !",
  ifYouWouldLikeToBeRemindedToReport: "Si vous souhaitez qu'on vous rappelle de signaler votre test. Veuillez envisager de nous suivre sur les réseaux sociaux où nous publierons des rappels chaque lundi et vendredi pour signaler votre test.",
  subscribe: "S'abonner",
  emailAddress: "e-mail",
  areYouSubmittingThisOnBehalfOfSomeoneElse: "Est-ce que vous soumettez ces résultats pour quelqu’un d’autre?",
  whatWasYourReasonForTesting: "Quelle était la raison de votre test? (Cochez toutes les options valides)",
  symptoms: "À cause des symptômes",
  notifiedAsCloseContact: "À cause d’un contact étroit avec un cas de Covid-19",
  travel: "À cause d’un voyage",
  asymptomaticScreening: "À cause d’un dépistage asymptomatique",
  other: "Autres raisons ",
  numberOfTestsReportedEachDay: "Nombre de tests signalés chaque jour"
}

i18n.use(initReactI18next).init({
    fallbackLng: "en",
    resources: {
      en: {
        common: {
          ...englishTranslations
        }
      },
      fr: {
        common: {
          ...frenchTranslations
        }
      }
    },
    interpolation: {
      escapeValue: false
    },
  }
  );

export default i18n;
