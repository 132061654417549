import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Button } from "@material-ui/core";
import { logicAndState } from './logicAndState';
import { Link } from "react-router-dom";

const NavBar = styled.div`
    width: 100%;
    display: flex;
    padding-top: 1vh;
    background-color: white;
    border-bottom: 0.2rem solid darkgrey;
    margin-bottom: 1rem;
`;

const OntarioLogoAndTitleContainer = styled.div`
    margin-left: 5%;
    @media (min-width: 768px) {
        margin-left: 5rem;
    }
`;

const Title = styled.h1`
    width: 100%;
`;

const StyledButton = styled(Button)`
    && {
        position: fixed;
        top: 1rem;
        right: 1rem;
        z-index: 1000;
    }
`;

export const NavBarName = observer(() => {
    const { t } = useTranslation("common");

    return (
        <NavBar>
            <OntarioLogoAndTitleContainer>
                <Title>Rapid Report</Title>
            </OntarioLogoAndTitleContainer>
            {!logicAndState.isShowingSelfReportForm ? 
                <Link to="/form"> <StyledButton variant="contained">{t("goToSelfReportForm")}</StyledButton></Link>
                :
                <Link to="/data"> <StyledButton variant="contained">{t("goToDataPage")}</StyledButton></Link>
            }
        </NavBar>
    );
});