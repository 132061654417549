import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { logicAndState } from "./logicAndState";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { Button, Fab, Typography } from "@material-ui/core";
import Workbook from 'react-excel-workbook'
import TwitterIcon from './assets/free-twitter-logo-icon-2429-thumb.png';
import InstagramIcon from './assets/instagram.png';
import FacebookIcon from './assets/facebook.png';

const StyledDataPage = styled.div`
    width: 91vw;
    min-height: 70vh;
    margin: auto;
    background-color: white;
    padding: 0.5rem 2vw;
    margin-bottom: 1rem;
`;

const ChartsContainer = styled.div`
    @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

const ChartContainer = styled.div`
    @media (min-width: 768px) {
        max-width: 50%;
    }
`;

const NumberOfSubmittedTests = styled(Typography)`
    && {
        text-align: center;
        font-size: 2rem;
        margin-top: 2rem
    }
`;

const ChartTitle = styled(Typography)`
    && {
        color: black;
        text-align: center;
    }
`;

const DownloadDataButton = styled(Button)`
    && {
        display: block;
        margin: auto;
        margin-top: 1rem;
    }
`;

const SocialMediaAsk = styled(Typography)`
    && {
        padding-top: 1rem;
        font-size: 1.5rem;
        text-align: center;
        width: 95%;
        margin: auto;
        @media (max-width: 768px) {
            font-size: 1.25rem;
        }
    }
`;

const SocialMediaIconsContainer = styled.div`
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem;
`;

const Icon = styled.img`
    width: 60%;
    height: auto;
`;

const SocialMediaContainer = styled.div`
    margin-bottom: 2rem;
    @media (min-width: 768px) {
        max-width: 50%;
        margin-top: 2rem;
        margin-bottom: 0;
    }
`;


export const DataPage = observer(() => {
    const { loadingDataPage: loading, responses, numberOfReportedCasesPerDay, proportionOfPositiveTests, excelFriendlyJSON } = logicAndState;
    const { t } = useTranslation("common");

    const [graphWidthAndHeight, setGraphWidthAndHeight] = useState({ width: window.innerWidth * 0.9, height: 300 });

    useEffect(() => {
        if (window.innerWidth >= 768) {
            setGraphWidthAndHeight({ width: window.innerWidth * 0.45, height: window.innerHeight * 0.5 })
        }
    }, []);

    if (!loading) {
        return (
            <StyledDataPage>
                <NumberOfSubmittedTests> {`${responses.length} ${t("ofTestsReportedToTheSite")}`}</NumberOfSubmittedTests>
                <Workbook filename="data.xlsx" element={<DownloadDataButton style={{ backgroundColor: "#202020" }} variant="contained" color="success" className="btn btn-lg btn-primary">{t("download")}</DownloadDataButton>}>
                    <Workbook.Sheet data={excelFriendlyJSON} name="Sheet A">
                        <Workbook.Column label="result of test" value="resultOfTest" />
                        <Workbook.Column label="date test was taken" value="dateTestWasTaken" />
                        <Workbook.Column label="time Stamp" value="timeStamp" />
                        <Workbook.Column label="postal Code" value="postalCode" />
                        <Workbook.Column label="symptoms" value="symptoms" />
                        <Workbook.Column label="gender" value="gender" />
                        <Workbook.Column label="age group" value="ageGroup" />
                        <Workbook.Column label="number Of Vaccines Received" value="numberOfVaccinesReceived" />
                        <Workbook.Column label="travelled Outside Canada In Past 14 Days" value="travelledOutsideCanadaInPast14Days" />
                        <Workbook.Column label="Submitting on behalf of someone else" value="areYouSubmittingThisOnBehalfOfSomeoneElse" />
                        <Workbook.Column label="reason for testing" value="whatWasYourReasonForTesting" />
                    </Workbook.Sheet>
                </Workbook>
                <ChartsContainer>
                    <SocialMediaContainer>
                        <SocialMediaAsk>{t("ifYouWouldLikeToBeRemindedToReport")}</SocialMediaAsk>
                        <SocialMediaIconsContainer>
                            <a href="https://www.facebook.com/RapidReportCanada">
                                <Fab>
                                    <Icon src={FacebookIcon} />
                                </Fab>
                            </a>
                            <a href="https://twitter.com/ReportRapid">
                                <Fab>
                                    <Icon src={TwitterIcon} />
                                </Fab>
                            </a>

                            <a href="https://www.instagram.com/rapid_report/">
                                <Fab>
                                    <Icon src={InstagramIcon} />
                                </Fab>
                            </a>
                        </SocialMediaIconsContainer>
                    </SocialMediaContainer>
                    <ChartContainer>
                        <ChartTitle>{t("numberOfTestsReportedEachDay")}</ChartTitle>
                        <LineChart width={graphWidthAndHeight.width} height={graphWidthAndHeight.height} data={numberOfReportedCasesPerDay}>
                            <Line type="monotone" dataKey="numberOfReportedTests" stroke="#8884d8" />
                            <CartesianGrid stroke="#ccc" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                        </LineChart>
                    </ChartContainer>

                </ChartsContainer>
                
            </StyledDataPage>
        );
    }
    return (
        <div>
            loading...
        </div>
    )
});