import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styled from 'styled-components';
import { NavBarName } from './NavBar';
import { SymptomReportFormName } from './SymptomReportForm';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import { observer } from 'mobx-react-lite';
import { DataPage } from './dataPage';
import { EntrancePage } from './entrancePage';

const StyledApp = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #eaeaea;
  /* background-color: red; */
`;

export const App = observer(() => {
  return (
    <StyledApp>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Router>
          <NavBarName />
          <Routes>
            <Route path={"/"} element={<EntrancePage />} />
            <Route path={"/data"} element={<DataPage />} />
            <Route path={"/form"} element={<SymptomReportFormName />} />
          </Routes>
        </Router>
      </LocalizationProvider>
    </StyledApp>
  );
});
