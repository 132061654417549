import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Typography } from "@material-ui/core";
import { MobileDatePicker } from "@mui/lab";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AgeGroup, Gender, logicAndState, PotentialNumberOfVaccinesReceived, symptomsArray, typeAgeGroupArray } from "./logicAndState";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import i18next from "i18next";

const ContentContainer = styled.div`
    width: 91vw;
    margin: auto;
    background-color: white;
    padding: 0.5rem 2vw;
    margin-bottom: 1rem;
    border: solid lightgrey thin;
    @media (min-width: 768px) {
        width: 30rem;
    }
`;

const StyledFormLabel = styled(FormLabel)`
    && {
        color: black;
        margin-top: 2rem;
    };
`;

const FormLabelBlack = styled(FormLabel)`
    && {
        color: black;
    }
`;

const NonFormLabels = styled(Typography)`
    && {
        margin-top: 2rem;
    }
`;

const MobileDatePickerContainer = styled.div`
    width: 100%;
`;

const StyledSubmit = styled(Button)`
    && {
        margin-top: 2rem;
    }
`;

const OptionalQuestionsText = styled.p`
    font-size: 1.5rem;
    margin: 0;
    margin-top: 0.5rem;
`;

const OptionalQuestionsLineSeparator = styled.div`
    width: 100%;
    height: 4px;
    background-color: #2b2b2b;
    margin-top: 0.5rem;
`;

const ChangeLanguageButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1rem;
`;

const ChangeLanguageText = styled.p`
    font-size: 1.25rem;
    text-align: center;
    font-weight: bolder;
`;

const StyledButton = styled(Button)`
    && {
        display: block;
        @media (max-width: 768px) {
            width: 45%;
            margin: auto;
            margin-top: 1rem;
        }
    }
`;

const FirstThreeLettersOfPostalCodeInput = styled(TextField)`
    && {
        width: 20%;
        min-width: 0;
    }
`;

export const SymptomReportFormName = observer(() => {
    const { numberOfVaccinesReceived, dateTestWasTaken, resultOfTest, postalCode, symptoms, gender, ageGroup, travelledOutsideCanadaInPast14Days, areYouSubmittingThisOnBehalfOfSomeoneElse, whatWasYourReasonForTesting } = logicAndState;
    let navigate = useNavigate();
    const { t } = useTranslation("common");

    useEffect(() => {
        logicAndState.onInitializeSelfReportFormPage();
        return () => {
            logicAndState.onLeaveSelfReportFormPage()
        };
    }, []);

    const onSubmit = async () => {
        logicAndState.submit(t, navigate);
    }

    const changeLanguage = (newLanguage: string) => {
        i18next.changeLanguage(newLanguage);
        navigate(`/form?language=${newLanguage}`);
    }

    return (
        <>
            <ContentContainer>
                <ChangeLanguageText>{t("changeLanguage")}</ChangeLanguageText>
                <ChangeLanguageButtonContainer>
                    <StyledButton size="large" onClick={() => changeLanguage("en")} style={{ backgroundColor: "#202020" }} variant="contained">English</StyledButton>
                    <StyledButton size="large" onClick={() => changeLanguage("fr")} style={{ backgroundColor: "#202020" }} variant="contained">Français</StyledButton>
                </ChangeLanguageButtonContainer>
            </ContentContainer>
            <ContentContainer>
                <FormControl component="fieldset" required>
                    <FormLabelBlack>{t("wasYourTestResult")}</FormLabelBlack>
                    <RadioGroup
                        aria-label="gender"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={resultOfTest}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => logicAndState.updateResultOfTest(event.target.value)}
                    >
                        <FormControlLabel value="positive" control={<Radio />} label={t("positive")} />
                        <FormControlLabel value="negative" control={<Radio />} label={t("negative")} />
                        <FormControlLabel value="inconclusive" control={<Radio />} label={t("inconclusive")} />
                    </RadioGroup>
                </FormControl>

                <MobileDatePickerContainer>
                    <NonFormLabels>{t('onWhatDateDidYouPerformYourTest')}</NonFormLabels>
                    <MobileDatePicker
                        value={dateTestWasTaken}
                        onChange={(newValue: any) => logicAndState.dateTestWasTaken = newValue}
                        renderInput={(params: any) => <TextField {...params} label={t("clickHere")} />}
                        maxDate={moment()}
                    />
                </MobileDatePickerContainer>

                <NonFormLabels>{t("whatIsYourPostalCode")}</NonFormLabels>
                <FirstThreeLettersOfPostalCodeInput value={postalCode} onChange={(event: React.ChangeEvent<HTMLInputElement>) => logicAndState.updatePostalCode(event.target.value)} />

                <OptionalQuestionsText>{t("optionalQuestions")}</OptionalQuestionsText>
                <OptionalQuestionsLineSeparator />


                <NonFormLabels>{t("haveYouHadAnyOfTheFollowingSymptomsInThePastTenDays")}</NonFormLabels>
                <div>
                    {
                        symptomsArray.map(ele => <FormControlLabel control={<Checkbox onChange={() => (logicAndState.symptoms as any)[ele] = !(symptoms as any)[ele]} defaultChecked={false} />} label={t(ele)} />)
                    }
                </div>
                <FormControl component="fieldset">
                    <StyledFormLabel>{t("whatIsYourGender")}</StyledFormLabel>
                    <RadioGroup
                        name="radio-buttons-group"
                        value={gender}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => logicAndState.gender = event.target.value as Gender}
                    >
                        <FormControlLabel value="male" control={<Radio />} label={t("male")} />
                        <FormControlLabel value="female" control={<Radio />} label={t("female")} />
                        <FormControlLabel value="nonBinary" control={<Radio />} label={t("nonBinary")} />
                        <FormControlLabel value="prefer-not-to-say" control={<Radio />} label={t("preferNotToRespond")} />
                    </RadioGroup>
                </FormControl>

                <FormControl hiddenLabel fullWidth>
                    <StyledFormLabel>{t("whatIsYourAgeGroup")}</StyledFormLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ageGroup ? ageGroup : undefined}
                        label="Age"
                        onChange={(event: SelectChangeEvent<AgeGroup>) => logicAndState.ageGroup = event.target.value as AgeGroup}
                    >
                        {typeAgeGroupArray.map(ele => <MenuItem value={ele}>{ele}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl hiddenLabel fullWidth>
                    <StyledFormLabel>{t("howManyDosesOfCOVIDVaccineHaveYouReceived")}</StyledFormLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={numberOfVaccinesReceived ? numberOfVaccinesReceived : undefined}
                        label="Age"
                        onChange={(event: SelectChangeEvent<PotentialNumberOfVaccinesReceived>) => logicAndState.numberOfVaccinesReceived = event.target.value as PotentialNumberOfVaccinesReceived}
                    >
                        <MenuItem value={"0"}>0</MenuItem>
                        <MenuItem value={"1"}>1</MenuItem>
                        <MenuItem value={"2"}>2</MenuItem>
                        <MenuItem value={"3"}>3</MenuItem>
                        <MenuItem value={"4+"}>4+</MenuItem>
                    </Select>
                </FormControl>
                <FormControl component="fieldset">
                    <StyledFormLabel>{t("haveYouTravelledOutsideOfCanadaInThePast14Days")}</StyledFormLabel>
                    <RadioGroup
                        name="radio-buttons-group"
                        value={travelledOutsideCanadaInPast14Days === null ? undefined : (travelledOutsideCanadaInPast14Days ? "yes" : "no")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => logicAndState.travelledOutsideCanadaInPast14Days = event.target.value === "yes" ? true : false}
                    >
                        <FormControlLabel value={"yes"} control={<Radio />} label={t("yes")} />
                        <FormControlLabel value={"no"} control={<Radio />} label={t("no")} />
                    </RadioGroup>
                </FormControl>


                <FormControl component="fieldset">
                    <StyledFormLabel>{t("areYouSubmittingThisOnBehalfOfSomeoneElse")}</StyledFormLabel>
                    <RadioGroup
                        name="radio-buttons-group"
                        value={areYouSubmittingThisOnBehalfOfSomeoneElse === null ? undefined : (areYouSubmittingThisOnBehalfOfSomeoneElse ? "yes" : "no")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => logicAndState.areYouSubmittingThisOnBehalfOfSomeoneElse = event.target.value === "yes" ? true : false}
                    >
                        <FormControlLabel value={"yes"} control={<Radio />} label={t("yes")} />
                        <FormControlLabel value={"no"} control={<Radio />} label={t("no")} />
                    </RadioGroup>
                </FormControl>

                <NonFormLabels>{t("whatWasYourReasonForTesting")}</NonFormLabels>
                <div>
                    <FormControlLabel control={<Checkbox onChange={() => logicAndState.whatWasYourReasonForTesting["symptoms"] = !whatWasYourReasonForTesting["symptoms"]} defaultChecked={false} />} label={t("symptoms")} />
                    <FormControlLabel control={<Checkbox onChange={() => logicAndState.whatWasYourReasonForTesting["notifiedAsCloseContact"] = !whatWasYourReasonForTesting["notifiedAsCloseContact"]} defaultChecked={false} />} label={t("notifiedAsCloseContact")} />
                    <FormControlLabel control={<Checkbox onChange={() => logicAndState.whatWasYourReasonForTesting["travel"] = !whatWasYourReasonForTesting["travel"]} defaultChecked={false} />} label={t("travel")} />
                    <FormControlLabel control={<Checkbox onChange={() => logicAndState.whatWasYourReasonForTesting["asymptomaticScreening"] = !whatWasYourReasonForTesting["asymptomaticScreening"]} defaultChecked={false} />} label={t("asymptomaticScreening")} />
                    <FormControlLabel control={<Checkbox onChange={() => logicAndState.whatWasYourReasonForTesting["other"] = !whatWasYourReasonForTesting["other"]} defaultChecked={false} />} label={t("other")} />
                </div>

                <StyledSubmit variant="outlined" onClick={onSubmit}>{t("submit")}</StyledSubmit>
            </ContentContainer>
        </>
    );
});